<template>
  <div class="MenuRoleEdit">
    <template v-if="menuRolePageData.display">
      <PocCardTable class="topPocCardTable">
        <template v-slot:headerTitle>权限{{ menuRolePageData.title }}</template>
        <template v-slot:headerGroup>
          <el-button @click="Back">返回</el-button>
          <el-button type="primary" @click="Save" role="save" v-permission
            >保存</el-button
          >
        </template>
        <el-form
          :inline="true"
          :model="menuRolePageData.desObj"
          class="demo-form-inline"
        >
          <el-form-item label="权限编码">
            <el-input
              v-model="menuRolePageData.desObj.permissionCode"
              disabled
              placeholder="权限编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="权限名称">
            <el-input
              v-model="menuRolePageData.desObj.permissionName"
              placeholder="权限名称"
            ></el-input>
          </el-form-item>
        </el-form>
      </PocCardTable>
      <PocCardTable>
        <div class="headerAllSelect">
          <div class="leftTitle">已分配权限({{ allocated }})</div>
          <el-button
            type="primary"
            size="mini"
            :disabled="isAdd"
            role="addpermissions"
            v-permission
            @click="addPre"
            >编辑权限</el-button
          >
        </div>
        <PocTable
          v-loading="menuRolePageData.loading"
          :data="menuRolePageData.tableData"
          :current-page.sync="pageConfig.pageNum"
          :total="menuRolePageData.total"
          stripe
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
        >
          <el-table-column
            v-for="item in columnConfig"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.key === 'no' ? 60 : null"
            :min-width="item.width"
            show-overflow-tooltip
          ></el-table-column>
        </PocTable>
      </PocCardTable>
      <scopeTransfer
        ref="scopeTransferRef"
        @onConfirm="onConfirm"
        dialog-title="分配权限"
      />
    </template>
    <el-skeleton animated v-else />
  </div>
</template>

<script>
import { detailsColumnConfig } from '@/views/menuRoleManage/TableColumnConfig'
import { sysPermissionsGetData } from '@/views/menuRoleManage/Hooks/sysPermissionsGetData'
import { sysPermissionsSave } from '@/api/role'
import scopeTransfer from '@/components/ScopeTransfer/ScopeTransfer'
import { getItem, removeItem, setItem } from '@/utils/storage'
const { Message } = $PCommon

export default {
  name: 'MenuRoleEdit',
  mixins: [$PCommon.TableMixin],
  components: {
    scopeTransfer
  },

  data() {
    return {
      columnConfig: detailsColumnConfig,
      menuRolePageData: {
        loading: false,
        desObj: {
          permissionCode: '',
          permissionName: '',
          assignedList: ''
        },
        orgTreeData: [],
        tableData: [],
        selectOrgTreeData: '',
        total: 0,
        id: '',
        status: '',
        display: true,
        title: ''
      }
    }
  },
  created() {
    this.init()
  },
  computed: {
    allocated() {
      return this.menuRolePageData.tableData.length
    },
    isAdd() {
      return this.menuRolePageData.status === 'add'
    }
  },
  watch: {
    $route() {
      this.init()
      //等其他初始化方法
    }
  },
  methods: {
    getData() {
      const obj = {
        permissionsId: this.menuRolePageData.id,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum
      }
      this.menuRolePageData.desObj.assignedList = getItem('assignedList') || ''
      sysPermissionsGetData(
        obj,
        this.menuRolePageData,
        this.menuRolePageData.status
      )
    },
    init() {
      this.menuRolePageData.id = this.$route.query.id
      this.menuRolePageData.status = this.$route.query.status
      if (this.menuRolePageData.status) {
        if (this.menuRolePageData.status === 'edit') {
          this.menuRolePageData.title = '编辑'
        } else {
          this.menuRolePageData.title = '新增'
        }
        this.getData()
      } else {
        this.menuRolePageData.display = false
        setTimeout(() => {
          this.Back()
        }, 1000)
      }
    },
    queryApi() {
      this.getData()
    },
    Save(isBack = true) {
      const obj = {
        assignedList: this.menuRolePageData.desObj.assignedList,
        orgIds: this.menuRolePageData.selectOrgTreeData,
        id: this.menuRolePageData.id,
        permissionName: this.menuRolePageData.desObj.permissionName
      }
      sysPermissionsSave(obj).then((res) => {
        if (res.code === 200) {
          Message.success(res.msg)
          if (isBack) {
            if (this.menuRolePageData.status !== 'edit') {
              this.$router.replace({
                path: '/system/permissionManage/edit',
                query: {
                  id: res.data,
                  status: 'edit'
                }
              })
            } else {
              this.Back()
            }
          } else {
            this.getData()
          }
        }
      })
    },
    onConfirm(val) {
      this.menuRolePageData.desObj.assignedList = val
      setItem('assignedList', val)
      this.Save(false)
    },
    Back() {
      removeItem('assignedList')
      this.$router.push({
        path: '/system/permissionManage'
      })
    },
    addPre() {
      this.$refs.scopeTransferRef.show({
        id: this.menuRolePageData.id,
        positions: 'pre',
        treeTitle: ['待分配权限', '已分配权限'],
        placeholder: '请输入菜单和按钮'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.MenuRoleEdit {
  :deep(.el-card) {
    .el-form {
      .el-form-item {
        margin-bottom: 0 !important;
      }
    }
    .el-card__body {
      padding: 20px;
      .headerAllSelect {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .leftTitle {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
